<template>
	<div id="Class" style="margin-top: 20px;">
		<div class="content" style="padding: 0">
			<div class="u-f">
				<div class="right">
					<div class="title u-f-item u-f-jsb">
						<div>共{{ total }}条数据</div>
						<div class="u-f-item"><el-button size="small" @click="openDialog(1)">添加班级</el-button></div>
					</div>
					<el-table
						:data="classList"
						height="65vh"
						ref="multipleTable"
						tooltip-effect="dark"
						style="width: 100%;border: 1px solid #EEEEEE;"
						@selection-change="handleSelectionChange"
					>
						<el-table-column type="selection" width="55" align="center"></el-table-column>
						<el-table-column type="index" label="序号" width="70" align="center"></el-table-column>
						<el-table-column prop="code" label="班级代码" align="center"></el-table-column>
						<el-table-column prop="name" label="班级名称" align="center"></el-table-column>
						<el-table-column prop="max" label="最大人数" align="center"></el-table-column>
						<el-table-column prop="grade.name" label="年级" align="center"></el-table-column>
						<el-table-column prop="js.name" label="教室" align="center"></el-table-column>
						<el-table-column prop="teacher.nickname" label="班主任" align="center">
              <template v-slot="{ row }">
                <template v-for="item in row.teachers">
                  {{item.username}}
                </template>
              </template>
            </el-table-column>
						<el-table-column prop="xi.section_name" label="系部" align="center"></el-table-column>
						<el-table-column prop="zy.name" label="专业" align="center"></el-table-column>
						<el-table-column prop="school_campus.name" label="校区" align="center"></el-table-column>
						<el-table-column label="操作" width="150" align="center">
							<template slot-scope="scope">
                <el-button @click="openDialog(2, scope.row)" type="text" size="small">编辑</el-button>
                <el-button @click="onGraduate(scope.row)" type="text" size="small">毕业</el-button>
							</template>
						</el-table-column>
					</el-table>
					<div class="u-f-item u-f-jsb" style="padding: 15px 0;">
						<el-button type="danger" plain size="mini" @click="delclass">删除</el-button>
						<el-pagination
							@size-change="handleSizeChange"
							@current-change="handleCurrentChange"
							:current-page.sync="page"
							:page-size="limit"
							layout="total, prev, pager, next"
							:total="total"
						></el-pagination>
					</div>
				</div>
			</div>
		</div>
		<!-- 添加 编辑班级-->
		<el-dialog :title="VisibleTitle" :visible.sync="addVisible" width="500px" :before-close="handleClose">
			<!--选择 -->
      <v-teacher-select v-model="selectShow" @select="onTeacherSelect" />
			<div class="edit-box">
				<div class="u-f-item input-box">
					<div class="input-lebal">
						<span>*</span>
						班级代码
					</div>
					<el-input v-model="code" placeholder="请输入班级代码" style="width: 70%;"></el-input>
				</div>
				<div class="u-f-item input-box">
					<div class="input-lebal">
						<span>*</span>
						班级名称
					</div>
					<el-input v-model="name" placeholder="请输入班级名称" style="width: 70%;"></el-input>
				</div>
				<div class="u-f-item input-box">
					<div class="input-lebal">
						<span>*</span>
						所属校区
					</div>
					<el-select v-model="school_campus_id" filterable placeholder="请选择" style="width: 70%;">
						<el-option v-for="item in schoolList" :key="item.id" :label="item.name" :value="item.id"></el-option>
					</el-select>
				</div>
				<div class="u-f-item input-box">
					<div class="input-lebal">
						<span>*</span>
						年级
					</div>
					<el-select v-model="grade_id" filterable placeholder="请选择" style="width: 70%;">
						<el-option v-for="item in gradeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
					</el-select>
				</div>
				<div class="u-f-item input-box">
					<div class="input-lebal">最大人数</div>
					<el-input v-model="max" placeholder="请输入最大人数" style="width: 70%;"></el-input>
				</div>
				<div class="u-f-item input-box">
					<div class="input-lebal">
						<span>*</span>
						班主任
					</div>
					<div class="u-f-item input-item" style="width: 70%;">
            <el-input :value="teacherSelectText" readonly style="width: 90%" />
						<el-button style="margin-left: 10px;" @click="selectShow = true">选择</el-button>
					</div>
				</div>
				<div class="u-f-item input-box">
					<div class="input-lebal">
						<span>*</span>
						建班年月
					</div>
					<el-date-picker format="yyyy-MM"
      value-format="yyyy-MM" v-model="start_time" type="month" placeholder="选择建班年月"></el-date-picker>
				</div>
				<div class="u-f-item input-box">
					<div class="input-lebal">
						<span>*</span>
						教室
					</div>
					<el-select v-model="js_id" filterable placeholder="请选择" style="width: 70%;">
						<el-option v-for="item in classRoomList" :key="item.id" :label="item.name" :value="item.id"></el-option>
					</el-select>
				</div>
				<div class="u-f-item input-box">
					<div class="input-lebal">
						<span>*</span>
						系部
					</div>
					<el-select v-model="xi_id" filterable placeholder="请选择" style="width: 70%;">
						<el-option v-for="item in xiBuList" :key="item.id" :label="item.section_name" :value="item.id"></el-option>
					</el-select>
				</div>
				<div class="u-f-item input-box">
					<div class="input-lebal">专业</div>
					<el-select v-model="zy_id" filterable placeholder="请选择专业" style="width: 70%;">
						<el-option v-for="item in ZyList" :key="item.id" :label="item.name" :value="item.id"></el-option>
					</el-select>
				</div>
				<!-- <div class="u-f-item input-box">
					<div class="input-lebal">班长</div>
					<el-select v-model="zy_id" filterable placeholder="请选择专业" style="width: 70%;">
						<el-option v-for="item in ZyList" :key="item.id" :label="item.name" :value="item.id"></el-option>
					</el-select>
				</div> -->
			</div>
			<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
				<el-button @click="closeVisible" size="small">取 消</el-button>
				<el-button type="primary" size="small" @click="submit">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
import VTeacherSelect from "@/components/teacher-select";
import v2Class from "@/api/v2/class";
export default {
  components: {VTeacherSelect},
  props: {
		condition: {
			type: Number,
			default: 0
		},
		year: {
			type: Number,
			default: 0
		},
		semester: {
			type: Number,
			default: 0
		}
	},
	data() {
		return {
			classList: [],
			page: 1,
			limit: 10,
			total: 0,
			VisibleTitle: '',
			addVisible: false,
			type: -1,
			id: '',
			ids: [],
			gradeList: [],
			studentList: [],
			teachers: '',
			selectShow: false,
			selectList: [],
			teacher_list: [],
			key: '',
			typeIndex: 0,
			dataIndex: 0,
			ZyList: [],
			schoolList: [],
			xiBuList:[],
			classRoomList:[],
			code:'',
			name: '',
			school_campus_id:'',
			max: '',
			grade_id: '',
			zy_id: '',
			xi_id:'',
			status:1,
			start_time:'',
			js_id:'',
			student_id:''
		};
	},
  computed: {
    teacherSelectText() {
      return this.selectList.map(item => item.username).join(',')
    }
  },
	created() {
		this.classData();
		this.getRoomList();
		this.getZyList();
		this.gradeData();
		this.getschoolList();
		this.getXiList()
	},
	methods: {
    /**
     * 毕业被点击
     */
    async onGraduate(row) {
      await this.$confirm('确定将此班级毕业？')
      await v2Class.graduate(row.id)
      this.$message.success('保存成功')
      const index = this.classList.findIndex(_ => _.id === row.id)
      if (index > -1) {
        this.classList.splice(index, 1)
      }
    },
    /**
     * 老师选择回调
     */
    onTeacherSelect(val, info) {
      this.teachers = val[0]
      this.selectList = info
    },
		getXiList() {
			this.$api.setting.getXiList({}).then(res => {
				this.xiBuList = res.data.data.rows;
			});
		},
		getZyList() {
			this.$api.setting.getZyList({}).then(res => {
				this.ZyList = res.data.data.rows;
			});
		},
		dataTap(index) {
			this.dataIndex = index;
		},
		typeTap(index) {
			this.typeIndex = index;
		},
		handleSelectionChange(val) {
			let arr = [];
			for (let i in val) {
				arr.push(val[i].id);
			}
			this.ids = arr;
			console.log(this.ids);
		},
		handleSizeChange(val) {
			console.log(`每页 ${val} 条`);
		},
		handleCurrentChange(val) {
			this.page = val;
			this.classData();
		},
		handleClose(done) {
			this.initialize();
			done();
		},
		// 初始化表单
		initialize() {
			this.code = '';
			this.name = '';
			this.school_campus_id = '';
			this.max = '';
			this.grade_id = '';
			this.zy_id = '';
			this.xi_id = '';
			this.status = 1;
			this.start_time = '';
			this.js_id = '';
			this.student_id = '';
			this.teachers = '';
		},
		// 年级列表
		gradeData() {
			this.$api.setting.getGradeList({}).then(res => {
				if (res.data.code == 1) {
					this.gradeList = res.data.data.rows;
				}
			});
		},
		// 班级列表
		classData() {
			this.$api.setting
				.getClassList({
					limit: this.limit,
					page: this.page
				})
				.then(res => {
					this.classList = res.data.data.rows;
					this.total = res.data.data.total;
				});
		},
		// 房间列表
		getRoomList() {
			this.$api.setting.getClassRoom({}).then(res => {
				if (res.data.code == 1) {
					this.classRoomList = res.data.data.rows;
				}
			});
		},
		// 弹窗
		openDialog(type, item) {
			this.type = type;
			if (type === 1) {
				this.VisibleTitle = '添加班级';
			} else if (type === 2) {
				this.VisibleTitle = '编辑班级';
				this.id = item.id;
				this.code = item.code;
				this.name = item.name;
				this.school_campus_id = item.school_campus_id;
				this.max = item.max;
				this.grade_id = item.grade_id;
				this.teachers = item.teacher_id;
				this.zy_id = item.zy_id;
				this.xi_id = item.xi_id;
				this.start_time = item.start_time;
				this.js_id = item.js_id;
				this.student_id = item.student_id;
        this.selectList = item.teachers ?? []
			} else if (type == 3) {
				this.VisibleTitle = item.name;
				this.getStudentList(item);
			}
			this.addVisible = true;
		},
		// 关闭弹窗
		closeVisible() {
			this.initialize();
			this.addVisible = false;
		},
		// 添加、编辑班级
		submit() {
			if (this.type == 1) {
				this.addclass();
			} else if (this.type == 2) {
				this.editclass();
			}
			this.initialize();
		},
		// 添加班级
		addclass() {
			this.$api.setting
				.addClass({
					code:this.code, 	
					name:this.name,
					school_campus_id:this.school_campus_id,
					zy_id:this.zy_id,
					grade_id:this.grade_id,
					max:this.max,
					teacher_id:this.teachers,
					xi_id:this.xi_id,
					start_time:this.start_time,
					js_id:this.js_id,
					student_id:this.student_id
				})
				.then(res => {
					if (res.data.code == 1) {
						this.$message.success('添加成功');
						this.classData();
						this.addVisible = false;
					} else {
						this.$message.error(res.data.msg);
					}
				});
		},
		// 编辑班级
		editclass() {
			this.$api.setting
				.editClass({
					id: this.id,
					code:this.code,
					name:this.name,
					school_campus_id:this.school_campus_id,
					zy_id:this.zy_id,
					grade_id:this.grade_id,
					max:this.max,
					teacher_id:this.teachers,
					xi_id:this.xi_id,
					start_time:this.start_time,
					js_id:this.js_id,
					student_id:this.student_id
				})
				.then(res => {
					if (res.data.code == 1) {
						this.$message.success('添加成功');
						this.classData();
						this.addVisible = false;
					} else {
						this.$message.error(res.data.msg);
					}
				});
		},
		// 删除班级
		delclass() {
			let _this = this;
			if (this.ids.length) {
				this.$confirm('确定要删除？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消'
				}).then(() => {
					this.$api.setting
						.delClass({
							id: this.ids
						})
						.then(res => {
							if (res.data.code == 1) {
								_this.$message.success('删除成功');
								_this.classData();
							} else {
								_this.$message.error(res.data.msg);
							}
						});
				});
			} else {
				this.$message.error('请选择要删除的班级');
			}
		},
		// 重置
		reset() {
			this.teachers = '';
		},
		teachTap(item) {
		},
		unique(arr) {
			// 根据唯一标识orderId来对数组进行过滤
			const res = new Map(); //定义常量 res,值为一个Map对象实例 //返回arr数组过滤后的结果，结果为一个数组   过滤条件是，如果res中没有某个键，就设置这个键的值为1
			return arr.filter(arr => !res.has(arr) && res.set(arr, 1));
		},
		unique2(arr) {
			// 根据唯一标识orderId来对数组进行过滤
			const res = new Map(); //定义常量 res,值为一个Map对象实例 //返回arr数组过滤后的结果，结果为一个数组   过滤条件是，如果res中没有某个键，就设置这个键的值为1
			return arr.filter(arr => !res.has(arr.teacher_id) && res.set(arr.teacher_id, 1));
		},
		getschoolList() {
			this.$api.setting.schoolCampusIndex({}).then(res => {
				if (res.data.code == 1) {
					this.schoolList = res.data.data.rows;
				}
			});
		}
	}
};
</script>

<style lang="scss">
#Class {
	.content {
		margin-top: 20px;
		background-color: #ffffff;
		height: 79vh;
		.right {
			width: 100%;
			height: 75vh;
			padding: 25px 50px;
			.title {
				padding: 0 0 15px 0;
			}
			.el-table {
				table-layout: fixed;
			}
			.el-table th > .cell,
			.el-table .cell {
				overflow: hidden; /*超出部分隐藏*/
				white-space: nowrap; /*不换行*/
				text-overflow: ellipsis; /*超出部分文字以...显示*/
			}
			.el-table thead {
				background: #fafafa !important;
			}
			.el-table th {
				padding: 15px 5px !important;
				background: #fafafa !important;
			}
			.el-table::before {
				left: 0;
				bottom: 0;
				width: 100%;
				height: 0;
			}
			.el-table .btn {
				padding: 5px 10px;
				text-align: center;
				font-size: 12px;
			}
		}
	}
	.el-dialog__header {
		border-bottom: 1px solid #eeeeee;
		padding: 10px 20px;
		.el-dialog__title {
			font-size: 16px;
		}
	}
	.el-dialog__body {
		padding: 0 20px 35px 20px;
		overflow-y: auto;
		max-height: 600px;
		.edit-box {
			.input-box {
				margin-top: 35px;
				padding-left: 15px;
				.input-lebal {
					width: 20%;
					text-align: right;
					color: #aeb5b8;
					margin-right: 10px;
					span {
						color: #ff7a65;
						margin-right: 3px;
					}
				}
				.el-icon-remove-outline {
					font-size: 1.5625vw;
					color: #d7d9db;
					margin-left: 10px;
				}
				.input-item {
					.el-select .el-input.is-disabled .el-input__inner {
						cursor: default;
					}
					.el-input.is-disabled .el-input__inner {
						background-color: #ffffff;
						cursor: default;
					}
					.el-input__prefix,
					.el-input__suffix {
						display: none;
					}
				}
			}
			.avatar-uploader .el-upload {
				border: 1px dashed #d9d9d9;
				border-radius: 6px;
				cursor: pointer;
				position: relative;
				overflow: hidden;
			}
			.avatar-uploader .el-upload:hover {
				border-color: #409eff;
			}
			.avatar-uploader-icon {
				font-size: 28px;
				color: #8c939d;
				width: 90px;
				height: 90px;
				line-height: 90px;
				text-align: center;
			}
			.avatar {
				width: 90px;
				height: 90px;
				display: block;
			}
		}
		.header {
			padding: 10px 0;
			font-weight: bold;
			font-size: 16px;
		}
		.con-box {
			display: flex;
			flex-wrap: wrap;
			max-height: 60vh;
			overflow-y: scroll;
			.con-item {
				width: 120px;
				background: #f1f1f1;
				margin-right: 10px;
				margin-bottom: 10px;
				padding: 10px;
				border-radius: 10px;
				.user-pic {
					width: 40px;
					height: 40px;
					border-radius: 50%;
					background-color: #007aff;
					color: #ffffff;
					margin-right: 10px;
				}
				.nv {
					background-color: #cdafb8;
				}
			}
		}
		.con-box > div:nth-child(3n) {
			margin-right: 0;
		}
	}
	.el-dialog__footer {
		padding: 15px 20px 15px;
		box-shadow: 0px -5px 10px -5px #eeeeee;
	}

	.el-table__column-filter-trigger {
		.el-icon-arrow-down:before {
			content: '\E790';
			font-size: 16px;
		}
	}
}
.selectShow {
	.el-dialog__header {
		border-bottom: 1px solid #eeeeee;
		padding: 10px 20px;
		.el-dialog__title {
			font-size: 16px;
		}
	}
	.el-dialog__body {
		padding: 0;
		.user-list-box {
			.user-list-head {
				padding: 10px 20px;
				border-bottom: 1px solid #eeeeee;
				.user-type-list {
					.user-type-item {
						color: #8d9da6;
						cursor: pointer;
					}
					.active {
						color: #007aff;
						position: relative;
					}
					.active::after {
						position: absolute;
						width: 25px;
						height: 2px;
						background-color: #007aff;
						content: '';
						bottom: -5px;
						left: 0;
					}
					span {
						color: #cccccc;
						margin: 0 8px;
					}
				}
			}
			.user-list-content {
				height: 40vh;
				.left {
					height: 98%;
					overflow-y: auto;
					border-right: 1px solid #eeeeee;
					padding-top: 5px;
					.left-item {
						padding: 0 20px;
						height: 40px;
						line-height: 40px;
						color: #a4a4a4;
						cursor: pointer;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}
					.active {
						background-color: #e4f1ff;
						color: #2d8cf0;
						position: relative;
					}
					.active:after {
						position: absolute;
						top: 0;
						bottom: 0;
						right: 0;
						width: 2px;
						content: '';
						background-color: #2d8cf0;
					}
				}
				.right {
					height: 37vh;
					padding: 10px 25px;
					overflow-y: auto;
					.list {
						display: flex;
						flex-wrap: wrap;
						.item {
							cursor: pointer;
							padding: 5px 15px;
							border-radius: 3px;
							background-color: #f3f3f3;
							margin-right: 10px;
							margin-bottom: 10px;
						}
						.active {
							background-color: #e4f1ff;
							color: #2d8cf0;
						}
					}
				}
			}
		}
	}
	.dialog-title {
		padding: 10px 0 5px 0;
	}
	.el-dialog__footer {
		padding: 15px 20px 15px;
		box-shadow: 0px -5px 10px -5px #eeeeee;
	}
}
</style>
