<template>
	<div id="department" style="margin-top: 20px;">
		<div class="content">
			<div class="title u-f-item u-f-jsb">
				<div>
					共{{total}}条数据
				</div>
				<div class="u-f-item">
					<el-button size="small" @click="openDialog(1)">添加系部</el-button>
				</div>
			</div>
			<el-table
				:data="classList"
				height="65vh"
				ref="multipleTable"
				tooltip-effect="dark"
				style="width: 100%;border: 1px solid #EEEEEE;"
				 @selection-change="handleSelectionChange"
			>
				<el-table-column type="selection" width="55" align="center"></el-table-column>
				<el-table-column type="index" label="序号" width="70" align="center"></el-table-column>
				<el-table-column prop="section_code" label="系部代码"  align="center"></el-table-column>
				<el-table-column prop="section_name" label="系部名称"  align="center"></el-table-column>
				<el-table-column prop="director.nickname" label="系主任"  align="center"></el-table-column>
				<el-table-column prop="work.nickname" label="系排课老师"  align="center"></el-table-column>
				<el-table-column prop="selection.nickname" label="系选课管理老师"  align="center"></el-table-column>
				<el-table-column prop="recruiting.nickname" label="系招生老师"  align="center"></el-table-column>
				<el-table-column label="操作" width="150" align="center">
					<template slot-scope="scope">
						<el-button @click="openDialog(2, scope.row)" type="text" size="small">编辑</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div class="u-f-item u-f-jsb" style="padding: 15px 0;">
				<el-button type="danger" plain size="mini" @click="delclass">删除</el-button>
				<el-pagination
					@size-change="handleSizeChange"
					@current-change="handleCurrentChange"
					:current-page.sync="page"
					:page-size="limit"
					layout="total, prev, pager, next"
					:total="total"
				></el-pagination>
			</div>
		</div>
		<!-- 添加 编辑班级-->
		<el-dialog :title="VisibleTitle" :visible.sync="addVisible" width="500px" :before-close="handleClose">
			<!--选择 -->
			<div class="selectShow">
				<el-dialog custom-class="selectShow" :visible.sync="selectShow" width="800px" :before-close="handleClose1" append-to-body>
					<div slot="title" class="u-f-item dialog-title"><div style="margin-right: 10px;">选择联系人</div></div>
					<div class="user-list-box">
						<div class="u-f-item u-f-jsb user-list-head">
							<div class="user-type-list u-f-item">
								<div
									class="user-type-item u-f-justify"
									@click="typeTap(index)"
									:class="typeIndex == index ? 'active' : ''"
									v-for="(item, index) in teacher_list"
									:key="index"
								>
									{{ item.name }}
									<span v-if="index + 1 < teacher_list.length">|</span>
								</div>
							</div>
							<!-- <el-input placeholder="请输入内容" prefix-icon="el-icon-search" style="width: 200px;" size="small" v-model="key" @change="searchTeacher"></el-input> -->
						</div>
						<div class="user-list-content u-f">
							<div class="u-f2 left" v-if="teacher_list.length">
								<div
									class="left-item"
									@click="dataTap(index)"
									:class="dataIndex == index ? 'active' : ''"
									v-for="(item, index) in teacher_list[typeIndex].data"
									:key="index"
								>
									{{ item.name }}
								</div>
							</div>
							<div class="u-f4 right" v-if="teacher_list.length">
								<div class="list" v-if="teacher_list[typeIndex].data[dataIndex]">
									<div
										class="item"
										@click="teachTap(item)"
										:class="item.checked ? 'active' : ''"
										v-for="(item, index) in teacher_list[typeIndex].data[dataIndex].teacher"
									>
										{{ item.username }}
									</div>
								</div>
							</div>
						</div>
					</div>
					<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
						<el-button @click="reset" size="small">重置</el-button>
						<div class="u-f-item">
							<!-- <span style="color: #A4A4A4;margin-right: 10px;">{{ teachers.length }}/{{ nums.length }}</span> -->
							<el-button type="primary" size="small" @click="selectShow = false">确 定</el-button>
						</div>
					</span>
				</el-dialog>
			</div>
			<div class="edit-box">
				<div class="u-f-item input-box">
					<div class="input-lebal">
						<span>*</span>
						系部代码
					</div>
					<el-input v-model="section_code" placeholder="请输入系部代码" style="width: 80%;"></el-input>
				</div>
				<div class="u-f-item input-box">
					<div class="input-lebal">
						<span>*</span>
						系部名称
					</div>
					<el-input v-model="section_name" placeholder="请输入系部名称" style="width: 80%;"></el-input>
				</div>
				<div class="u-f-item input-box">
					<div class="input-lebal">系主任</div>
					<div class="u-f-item input-item" style="width: 75%;">
						<el-select v-model="director_user_id" popper-class="el_option" style="width: 90%;">
							<el-option style="display: none;" v-for="(item, index) in selectList" :key="index" :label="item.username" :value="item.teacher_id"></el-option>
						</el-select>
						<el-button style="margin-left: 10px;" @click="selectShowOpen(1)">选择</el-button>
					</div>
				</div>
				<div class="u-f-item input-box">
					<div class="input-lebal">系排课老师</div>
					<div class="u-f-item input-item" style="width: 75%;">
						<el-select v-model="work_user_id" popper-class="el_option" style="width: 90%;">
							<el-option style="display: none;" v-for="(item, index) in selectList" :key="index" :label="item.username" :value="item.teacher_id"></el-option>
						</el-select>
						<el-button style="margin-left: 10px;" @click="selectShowOpen(2)">选择</el-button>
					</div>
				</div>
				<div class="u-f-item input-box">
					<div class="input-lebal">系选课管理老师</div>
					<div class="u-f-item input-item" style="width: 75%;">
						<el-select v-model="selection_user_id" popper-class="el_option" style="width: 90%;">
							<el-option style="display: none;" v-for="(item, index) in selectList" :key="index" :label="item.username" :value="item.teacher_id"></el-option>
						</el-select>
						<el-button style="margin-left: 10px;" @click="selectShowOpen(3)">选择</el-button>
					</div>
				</div>
				<div class="u-f-item input-box">
					<div class="input-lebal">系部招生老师</div>
					<div class="u-f-item input-item" style="width: 75%;">
						<el-select v-model="recruiting_user_id" popper-class="el_option" style="width: 90%;">
							<el-option style="display: none;" v-for="(item, index) in selectList" :key="index" :label="item.username" :value="item.teacher_id"></el-option>
						</el-select>
						<el-button style="margin-left: 10px;" @click="selectShowOpen(4)">选择</el-button>
					</div>
				</div>
			</div>
			<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
				<el-button @click="closeVisible" size="small">取 消</el-button>
				<el-button type="primary" size="small" @click="submit">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
export default {
	props: {
		condition: {
			type: Number,
			default: 0
		},
		year: {
			type: Number,
			default: 0
		},
		semester: {
			type: Number,
			default: 0
		}
	},
	data() {
		return {
			sousuo: '',
			classList: [],
			page: 1,
			limit: 10,
			total: 0,
			VisibleTitle: '',
			addVisible: false,
			type: -1,
			id: '',
			ids: [],
			selectShow:false,
			selectList:[],
			teacher_list:[],
			key: '',
			typeIndex: 0,
			dataIndex: 0,
			section_code:'',
			section_name:'',
			director_user_id:'',
			work_user_id:'',
			selection_user_id:'',
			recruiting_user_id:'',
			selectType:1
		};
	},
	created() {
		this.classData();
	},
	methods: {
		handleClose1(done){
			this.typeIndex = 0;
			this.dataIndex = 0;
			done()
		},
		selectShowOpen(type){
			this.typeIndex = 0;
			this.dataIndex = 0;
			this.selectType = type;
			this.teacherList()
			this.selectShow = true;
		},
		handleSelectionChange(val) {
			let arr = [];
			for(let i in val){
				arr.push(val[i].id);
			}
			this.ids = arr;
		},
		handleSizeChange(val) {
			console.log(`每页 ${val} 条`);
		},
		handleCurrentChange(val) {
			this.page = val;
			this.classData()
		},
		handleClose(done){
			this.initialize()
			done()
		},
		// 初始化表单
		initialize(){
			this.section_code = '';
			this.section_name = '';
			this.director_user_id = '';
			this.work_user_id = '';
			this.selection_user_id = '';
			this.recruiting_user_id = '';
		},
		// 班级列表
		classData(){
			this.$api.setting.getXiList({
				limit: this.limit,
				page: this.page
			}).then(res=>{
				if(res.data.code==1){
					this.classList = res.data.data.rows;
					this.total = res.data.data.total;
				}
			})
		},
		// 弹窗
		openDialog(type,item){
			this.type = type;
			if(type==1){
				this.VisibleTitle = '添加系部';
			}else if(type==2){
				this.VisibleTitle = '编辑系部';
				this.id = item.id;
				this.section_code = item.section_code;
				this.section_name = item.section_name;
				this.director_user_id = item.director_user_id;
				this.work_user_id = item.work_user_id;
				this.selection_user_id = item.selection_user_id;
				this.recruiting_user_id = item.recruiting_user_id;
			}
			this.addVisible = true;
		},
		// 关闭弹窗
		closeVisible(){
			this.initialize()
			this.addVisible = false;
		},
		// 添加、编辑班级
		submit(){
			if(this.type==1){
				this.addclass()
			}else if(this.type==2){
				this.editclass()
			}
			this.initialize()
		},
		// 添加班级
		addclass(){
			this.$api.setting.addXi({
				section_code:this.section_code,
				section_name:this.section_name,
				director_user_id:this.director_user_id,
				work_user_id:this.work_user_id,
				selection_user_id:this.selection_user_id,
				recruiting_user_id:this.recruiting_user_id
			}).then(res=>{
				if (res.data.code == 1) {
					this.$message.success('添加成功');
					this.classData();
					this.addVisible = false;
				} else {
					this.$message.error(res.data.msg);
				}
			})
		},
		// 编辑班级
		editclass(){
			this.$api.setting.editXi({
				id: this.id,
				section_code:this.section_code,
				section_name:this.section_name,
				director_user_id:this.director_user_id,
				work_user_id:this.work_user_id,
				selection_user_id:this.selection_user_id,
				recruiting_user_id:this.recruiting_user_id
			}).then(res=>{
				if (res.data.code == 1) {
					this.$message.success('编辑成功');
					this.classData();
					this.addVisible = false;
				} else {
					this.$message.error(res.data.msg);
				}
			})
		},
		// 删除班级
		delclass(){
			let _this = this;
			if(this.ids.length){
				this.$confirm('确定要删除？','提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消'
				}).then(()=>{
					this.$api.setting.delXi({
						id:this.ids
					}).then(res=>{
						if (res.data.code == 1) {
							_this.$message.success('删除成功');
							_this.classData();
						} else {
							_this.$message.error(res.data.msg);
						}
					})
				})
			}else{
				this.$message.error('请选择要删除的班级')
			}
		},
		// 教师列表
		teacherList() {
			this.$api.pepople.teacherList({}).then(res => {
				if (res.data.code == 1) {
					this.teacher_list = res.data.data;
					for (let i in this.teacher_list) {
						for (let j in this.teacher_list[i].data) {
							for (let t in this.teacher_list[i].data[j].teacher) {
								this.selectList = this.selectList.concat(this.teacher_list[i].data[j].teacher[t]);
							}
						}
					}
					// this.nums = this.unique(this.nums);
					this.selectList = this.unique2(this.selectList);
				}
			});
		},
		// 重置
		reset() {
			if(this.selectType==1){
				this.director_user_id = '';
			}else if(this.selectType==2){
				this.work_user_id = '';
			}else if(this.selectType==3){
				this.selection_user_id = '';
			}else if(this.selectType==4){
				this.recruiting_user_id = '';
			}
			this.teacherList();
		},
		teachTap(item) {
			for (let i in this.teacher_list) {
				for (let j in this.teacher_list[i].data) {
					for (let t in this.teacher_list[i].data[j].teacher) {
						if (this.teacher_list[i].data[j].teacher[t].teacher_id == item.teacher_id && !this.teacher_list[i].data[j].teacher[t].checked) {
							this.teacher_list[i].data[j].teacher[t].checked = true;
						} else {
							this.teacher_list[i].data[j].teacher[t].checked = false;
						}
					}
				}
			}
			if(this.selectType==1){
				this.director_user_id = item.teacher_id;
			}else if(this.selectType==2){
				this.work_user_id = item.teacher_id;
			}else if(this.selectType==3){
				this.selection_user_id = item.teacher_id;
			}else if(this.selectType==4){
				this.recruiting_user_id = item.teacher_id;
			}
			
		},
		unique(arr) {
			// 根据唯一标识orderId来对数组进行过滤
			const res = new Map(); //定义常量 res,值为一个Map对象实例 //返回arr数组过滤后的结果，结果为一个数组   过滤条件是，如果res中没有某个键，就设置这个键的值为1
			return arr.filter(arr => !res.has(arr) && res.set(arr, 1));
		},
		unique2(arr) {
			// 根据唯一标识orderId来对数组进行过滤
			const res = new Map(); //定义常量 res,值为一个Map对象实例 //返回arr数组过滤后的结果，结果为一个数组   过滤条件是，如果res中没有某个键，就设置这个键的值为1
			return arr.filter(arr => !res.has(arr.teacher_id) && res.set(arr.teacher_id, 1));
		},
		// 切换分类
		typeTap(index) {
			this.typeIndex = index;
			this.dataIndex = 0;
		},
		dataTap(index) {
			this.dataIndex = index;
		}
	}
};
</script>

<style lang="scss" >
#department{
	.content {
		margin-top: 20px;
		background-color: #ffffff;
		height: 79vh;
		padding: 0 25px;
			.title {
				padding: 15px 0 15px 0;
			}
			.el-table{
				table-layout: fixed;
			}
			.el-table th > .cell,
			.el-table .cell {
				overflow: hidden; /*超出部分隐藏*/
				white-space: nowrap; /*不换行*/
				text-overflow: ellipsis; /*超出部分文字以...显示*/
			}
			.el-table thead {
				background: #fafafa !important;
			}
			.el-table th {
				padding: 15px 5px !important;
				background: #fafafa !important;
			}
			.el-table::before {
				left: 0;
				bottom: 0;
				width: 100%;
				height: 0;
			}
			.el-table .btn{
				padding: 5px 10px;
				text-align: center;
				font-size: 12px;
			}
		
	}
	.el-dialog__header {
		border-bottom: 1px solid #eeeeee;
		padding: 10px 20px;
		.el-dialog__title {
			font-size: 16px;
		}
	}
	.el-dialog__body {
		padding: 0 20px 35px 20px;
		.edit-box {
			.input-box {
				margin-top: 35px;
				padding-left: 15px;
				.input-lebal {
					width: 15%;
					text-align: right;
					color: #aeb5b8;
					margin-right: 10px;
					span {
						color: #ff7a65;
						margin-right: 3px;
					}
				}
				.el-icon-remove-outline {
					font-size: 1.5625vw;
					color: #d7d9db;
					margin-left: 10px;
				}
				.input-item{
					.el-select .el-input.is-disabled .el-input__inner {
						cursor: default;
					}
					.el-input.is-disabled .el-input__inner {
						background-color: #ffffff;
						cursor: default;
					}
					.el-input__prefix,
					.el-input__suffix {
						display: none;
					}
				}
			}
			.avatar-uploader .el-upload {
				border: 1px dashed #d9d9d9;
				border-radius: 6px;
				cursor: pointer;
				position: relative;
				overflow: hidden;
			}
			.avatar-uploader .el-upload:hover {
				border-color: #409eff;
			}
			.avatar-uploader-icon {
				font-size: 28px;
				color: #8c939d;
				width: 90px;
				height: 90px;
				line-height: 90px;
				text-align: center;
			}
			.avatar {
				width: 90px;
				height: 90px;
				display: block;
			}
		}
		.header{
			padding: 10px 0;
			font-weight: bold;
			font-size: 16px;
		}
		.con-box{
			display: flex;
			flex-wrap: wrap;
			max-height: 60vh;
			overflow-y: scroll;
			.con-item{
				width: 120px;
				background: #f1f1f1;
				margin-right: 10px;
				margin-bottom: 10px;
				padding: 10px;
				border-radius: 10px;
				.user-pic{
					width: 40px;
					height: 40px;
					border-radius: 50%;
					background-color: #007AFF;
					color: #FFFFFF;
					margin-right: 10px;
				}
				.nv{
					background-color: #CDAFB8;
				}
			}
		}
		.con-box>div:nth-child(3n){
			margin-right: 0;
		}
	}
	.el-dialog__footer {
		padding: 15px 20px 15px;
		box-shadow: 0px -5px 10px -5px #eeeeee;
	}
	
	.el-table__column-filter-trigger {
		.el-icon-arrow-down:before {
			content: '\E790';
			font-size: 16px;
		}
	}
	
}
.selectShow {
		.el-dialog__header {
			border-bottom: 1px solid #eeeeee;
			padding: 10px 20px;
			.el-dialog__title {
				font-size: 16px;
			}
		}
		.el-dialog__body {
			padding: 0;
			.user-list-box {
				.user-list-head {
					padding: 10px 20px;
					border-bottom: 1px solid #eeeeee;
					.user-type-list {
						.user-type-item {
							color: #8d9da6;
							cursor: pointer;
						}
						.active {
							color: #007aff;
							position: relative;
						}
						.active::after {
							position: absolute;
							width: 25px;
							height: 2px;
							background-color: #007aff;
							content: '';
							bottom: -5px;
							left: 0;
						}
						span {
							color: #cccccc;
							margin: 0 8px;
						}
					}
				}
				.user-list-content {
					height: 40vh;
					.left {
						height: 98%;
						overflow-y: auto;
						border-right: 1px solid #eeeeee;
						padding-top: 5px;
						.left-item {
							padding: 0 20px;
							height: 40px;
							line-height: 40px;
							color: #a4a4a4;
							cursor: pointer;
							white-space: nowrap;
							overflow: hidden;
							text-overflow: ellipsis;
						}
						.active {
							background-color: #e4f1ff;
							color: #2d8cf0;
							position: relative;
						}
						.active:after {
							position: absolute;
							top: 0;
							bottom: 0;
							right: 0;
							width: 2px;
							content: '';
							background-color: #2d8cf0;
						}
					}
					.right {
						height: 37vh;
						padding: 10px 25px;
						overflow-y: auto;
						.list {
							display: flex;
							flex-wrap: wrap;
							.item {
								cursor: pointer;
								padding: 5px 15px;
								border-radius: 3px;
								background-color: #f3f3f3;
								margin-right: 10px;
								margin-bottom: 10px;
							}
							.active {
								background-color: #e4f1ff;
								color: #2d8cf0;
							}
						}
					}
				}
			}
		}
		.dialog-title {
			padding: 10px 0 5px 0;
		}
		.el-dialog__footer {
			padding: 15px 20px 15px;
			box-shadow: 0px -5px 10px -5px #eeeeee;
		}
	}
</style>
