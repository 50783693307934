<template>
	<div id="major" style="margin-top: 20px;">
		<div class="content" style="padding: 0">
			<div class="u-f">
				<div class="right">
					<div class="title u-f-item u-f-jsb">
						<div>共{{ total }}条数据</div>
						<div class="u-f-item"><el-button size="small" @click="openDialog(1)">添加专业</el-button></div>
					</div>
					<el-table
						:data="classList"
						height="65vh"
						ref="multipleTable"
						tooltip-effect="dark"
						style="width: 100%;border: 1px solid #EEEEEE;"
						@selection-change="handleSelectionChange"
					>
						<el-table-column type="selection" width="55" align="center"></el-table-column>
						<el-table-column type="index" label="序号" width="70" align="center"></el-table-column>
						<el-table-column prop="code" label="专业代码" align="center"></el-table-column>
						<el-table-column prop="name" label="专业名称" align="center"></el-table-column>
						<el-table-column prop="years" label="专业修读年限" align="center"></el-table-column>
						<el-table-column prop="start_time" label="建立年月" align="center"></el-table-column>
						<el-table-column prop="jy.name" label="开课教研室" align="center"></el-table-column>
						<el-table-column label="系部/科组" align="center">
							<template slot-scope="scope">
								<span v-for="(item, i) in scope.row.xi">{{ item.section_name }}<span v-if="i+1 < scope.row.xi.length">,</span></span>
							</template>
						</el-table-column>
						<el-table-column label="状态" align="center">
							<template slot-scope="scope">
								<span v-if="scope.row.status == 1">有效</span>
								<span v-if="scope.row.status == 0">无效</span>
							</template>
						</el-table-column>
						<el-table-column label="操作" width="150" align="center">
							<template slot-scope="scope">
								<el-button @click="openDialog(2, scope.row)" type="text" size="small">编辑</el-button>
							</template>
						</el-table-column>
					</el-table>
					<div class="u-f-item u-f-jsb" style="padding: 15px 0;">
						<el-button type="danger" plain size="mini" @click="delclass">删除</el-button>
						<el-pagination
							@size-change="handleSizeChange"
							@current-change="handleCurrentChange"
							:current-page.sync="page"
							:page-size="limit"
							layout="total, prev, pager, next"
							:total="total"
						></el-pagination>
					</div>
				</div>
			</div>
		</div>
		<!-- 添加 编辑-->
		<el-dialog :title="VisibleTitle" :visible.sync="addVisible" width="500px" :before-close="handleClose">
			<!--选择 -->
			<div class="selectShow">
				<el-dialog custom-class="selectShow" :visible.sync="selectShow1" width="800px" :before-close="handleClose1" append-to-body>
					<div slot="title" class="u-f-item dialog-title"><div style="margin-right: 10px;">选择联系人</div></div>
					<div class="user-list-box">
						<div class="u-f-item u-f-jsb user-list-head">
							<div class="user-type-list u-f-item">
								<div
									class="user-type-item u-f-justify"
									@click="typeTap1(index)"
									:class="typeIndex1 == index ? 'active' : ''"
									v-for="(item, index) in teacher_list1"
									:key="index"
								>
									{{ item.name }}
									<span v-if="index + 1 < teacher_list1.length">|</span>
								</div>
							</div>
						</div>
						<div class="user-list-content u-f">
							<div class="u-f2 left" v-if="teacher_list1.length">
								<div
									class="left-item"
									@click="dataTap1(index)"
									:class="dataIndex1 == index ? 'active' : ''"
									v-for="(item, index) in teacher_list1[typeIndex1].data"
									:key="index"
								>
									{{ item.name }}
								</div>
							</div>
							<div class="u-f4 right" v-if="teacher_list1.length">
								<div class="list" v-if="teacher_list1[typeIndex1].data[dataIndex1]">
									<div
										class="item"
										@click="teachTap1(item)"
										:class="item.checked ? 'active' : ''"
										v-for="(item, index) in teacher_list1[typeIndex1].data[dataIndex1].teacher"
									>
										{{ item.username }}
									</div>
									<div class="">
										<el-button @click="QuanTap" type="primary" size="mini" v-if="!teacher_list1[typeIndex1].data[dataIndex1].isQuan">
											{{ typeIndex1 == 0 ? '学科' : '部门' }}全选
										</el-button>
										<el-button @click="QuanTap1" type="primary" size="mini" v-if="teacher_list1[typeIndex1].data[dataIndex1].isQuan">取消全选</el-button>
									</div>
								</div>
							</div>
						</div>
					</div>
					<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
						<div class="u-f-item">
							<el-button @click="reset1" size="small">重置</el-button>
							<el-button @click="allTap" type="primary" size="small" v-if="!isBnt">全选</el-button>
							<el-button @click="allTap1" type="primary" size="small" v-if="isBnt">取消全选</el-button>
						</div>
						<div class="u-f-item">
							<span style="color: #A4A4A4;margin-right: 10px;">{{ user_ids.length }}/{{ nums.length }}</span>
							<el-button type="primary" size="small" @click="selectShow1 = false">确 定</el-button>
						</div>
					</span>
				</el-dialog>
			</div>
			<!--选择 -->
			<div class="selectShow">
				<el-dialog custom-class="selectShow" :visible.sync="selectShow" width="800px" :before-close="handleClose1" append-to-body>
					<div slot="title" class="u-f-item dialog-title"><div style="margin-right: 10px;">选择联系人</div></div>
					<div class="user-list-box">
						<div class="u-f-item u-f-jsb user-list-head">
							<div class="user-type-list u-f-item">
								<div
									class="user-type-item u-f-justify"
									@click="typeTap(index)"
									:class="typeIndex == index ? 'active' : ''"
									v-for="(item, index) in teacher_list"
									:key="index"
								>
									{{ item.name }}
									<span v-if="index + 1 < teacher_list.length">|</span>
								</div>
							</div>
							<!-- <el-input placeholder="请输入内容" prefix-icon="el-icon-search" style="width: 200px;" size="small" v-model="key" @change="searchTeacher"></el-input> -->
						</div>
						<div class="user-list-content u-f">
							<div class="u-f2 left" v-if="teacher_list.length">
								<div
									class="left-item"
									@click="dataTap(index)"
									:class="dataIndex == index ? 'active' : ''"
									v-for="(item, index) in teacher_list[typeIndex].data"
									:key="index"
								>
									{{ item.name }}
								</div>
							</div>
							<div class="u-f4 right" v-if="teacher_list.length">
								<div class="list" v-if="teacher_list[typeIndex].data[dataIndex]">
									<div
										class="item"
										@click="teachTap(item)"
										:class="item.checked ? 'active' : ''"
										v-for="(item, index) in teacher_list[typeIndex].data[dataIndex].teacher"
									>
										{{ item.username }}
									</div>
								</div>
							</div>
						</div>
					</div>
					<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
						<el-button @click="reset" size="small">重置</el-button>
						<div class="u-f-item">
							<!-- <span style="color: #A4A4A4;margin-right: 10px;">{{ teachers.length }}/{{ nums.length }}</span> -->
							<el-button type="primary" size="small" @click="selectShow = false">确 定</el-button>
						</div>
					</span>
				</el-dialog>
			</div>
      <div class="edit-box">
        <div class="u-f-item input-box">
          <div class="input-lebal">
            <span>*</span>
            序号
          </div>
          <el-input v-model="sn" placeholder="请输入序号" style="width: 70%;"></el-input>
        </div>
        <div class="u-f-item input-box">
          <div class="input-lebal">
            <span>*</span>
            专业代码
          </div>
          <el-input v-model="code" placeholder="请输入专业名称" style="width: 70%;"></el-input>
        </div>
        <div class="u-f-item input-box">
          <div class="input-lebal">
            <span>*</span>
            专业名称
          </div>
          <el-input v-model="name" placeholder="请输入专业名称" style="width: 70%;"></el-input>
        </div>
        <div class="u-f-item input-box">
          <div class="input-lebal">
            <span>*</span>
            专业简称
          </div>
          <el-input v-model="shorter" placeholder="请输入专业简称" style="width: 70%;"></el-input>
        </div>
        <div class="u-f-item input-box">
          <div class="input-lebal">
            <span>*</span>
            专业修读年限
          </div>
          <el-input v-model="years" placeholder="请输入专业修读年限" style="width: 70%;"></el-input>
        </div>
        <div class="u-f-item input-box">
          <div class="input-lebal">
            <span>*</span>
            建立年月
          </div>
          <el-date-picker value-format="yyyy-MM" v-model="start_time" type="month" placeholder="选择年月"></el-date-picker>
        </div>
        <div class="u-f-item input-box">
          <div class="input-lebal">
            <span>*</span>
            开课教研室
          </div>
          <el-select v-model="jy_id" filterable  placeholder="请选择开课教研室" style="width: 70%;">
            <el-option v-for="item in jxlist" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </div>
        <div class="u-f-item input-box">
          <div class="input-lebal">
            <span>*</span>
            总学分
          </div>
          <el-input v-model="credit" placeholder="请输入总学分" style="width: 70%;"></el-input>
        </div>
        <div class="u-f-item input-box">
          <div class="input-lebal">
            <span>*</span>
            必修学分
          </div>
          <el-input v-model="compulsory_credits" placeholder="请输入必修学分" style="width: 70%;"></el-input>
        </div>
        <div class="u-f-item input-box">
          <div class="input-lebal">
            <span>*</span>
            限选学分
          </div>
          <el-input v-model="limited_credits" placeholder="请输入限选学分" style="width: 70%;"></el-input>
        </div>
        <div class="u-f-item input-box">
          <div class="input-lebal">
            <span>*</span>
            任选学分
          </div>
          <el-input v-model="optional_credits" placeholder="请输入任选学分" style="width: 70%;"></el-input>
        </div>
        <div class="u-f-item input-box">
          <div class="input-lebal">
            <span>*</span>
            负责人
          </div>
          <div class="u-f" style="width: 70%;">
            <el-select v-model="person_user_id" popper-class="el_option" style="width:90%;">
              <el-option style="display: none;" v-for="(item, index) in selectList" :key="index" :label="item.username" :value="item.teacher_id"></el-option>
            </el-select>
            <el-button style="margin-left: 10px;height: 40px;" @click="selectShow = true">选择</el-button>
          </div>
        </div>
        <div class="u-f-item input-box">
          <div class="input-lebal">
            <span>*</span>
            系部
          </div>
          <el-select v-model="xi_ids" multiple filterable  placeholder="请选择系部" style="width: 70%;">
            <el-option v-for="item in xibuList" :key="item.id" :label="item.section_name" :value="item.id"></el-option>
          </el-select>
        </div>
        <div class="u-f-item input-box">
          <div class="input-lebal">
            <span>*</span>
            是否有效
          </div>
          <el-radio-group v-model="status">
            <el-radio-button :label="1">有效</el-radio-button>
            <el-radio-button :label="0">无效</el-radio-button>
          </el-radio-group>
        </div>
        <div class="u-f input-box">
          <div class="input-lebal" style="padding-top: 10px;">
            <span>*</span>
            教师队伍
          </div>
          <div class="u-f" style="width: 70%;">
            <el-select v-model="user_ids" multiple popper-class="el_option" style="width:90%;">
              <el-option style="display: none;" v-for="(item, index) in selectList1" :key="index" :label="item.username" :value="item.teacher_id"></el-option>
            </el-select>
            <el-button style="margin-left: 10px;height: 40px;" @click="selectShow1 = true">选择</el-button>
          </div>
        </div>
      </div>
			<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
				<el-button @click="closeVisible" size="small">取 消</el-button>
				<el-button type="primary" size="small" @click="submit">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
export default {
  props: {
		condition: {
			type: Number,
			default: 0
		},
		year: {
			type: Number,
			default: 0
		},
		semester: {
			type: Number,
			default: 0
		}
	},
	data() {
		return {
			classList: [],
			page: 1,
			limit: 10,
			total: 0,
			VisibleTitle: '',
			addVisible: false,
			type: 1,
			id: '',
			ids: [],
			name: '',
			teachers: '',
			selectShow1:false,
			selectShow: false,
			selectList: [],
			teacher_list: [],
			selectList1: [],
			teacher_list1: [],
			key: '',
			typeIndex1: 0,
			dataIndex1: 0,
			typeIndex: 0,
			dataIndex: 0,
			person_user_id: '',
			sn:0,
			code:'',
			shorter:'',
			years:'',
			start_time:'',
			jy_id:'',
			credit:'',
			compulsory_credits:'',
			limited_credits:'',
			optional_credits:'',
			status:1,
			xi_ids:[],
			user_ids:[],
			xibuList:[],
			jxlist:[],
			isBnt:false,
			nums:[],
      checkDisciplines: [],
		};
	},
	created() {
		this.classData();
		this.teacherList();
		this.getJiaoyanList();
		this.getXibuList()
	},
	methods: {
		// 重置
		reset1() {
			this.user_ids = [];
			this.teacherList();
		},
		allTap(){
			let arr = [];
			for (let i in this.teacher_lis1t) {
				for (let j in this.teacher_list1[i].data) {
					for (let t in this.teacher_list1[i].data[j].teacher) {
						this.teacher_list1[i].data[j].teacher[t].checked = true;
						arr.push(this.teacher_list1[i].data[j].teacher[t].teacher_id);
					}
				}
			}
			this.user_ids = arr;
			this.user_ids = this.unique(this.user_ids);
			this.isBnt = true;
		},
		allTap1(){
			this.reset();
			this.isBnt = false;
		},
		QuanTap(){
			let arr = [];
			for (let t in this.teacher_list1[this.typeIndex1].data[this.dataIndex1].teacher) {
				this.teacher_list1[this.typeIndex1].data[this.dataIndex1].teacher[t].checked = true;
				arr.push(this.teacher_list1[this.typeIndex1].data[this.dataIndex1].teacher[t].teacher_id);
			}
			this.user_ids = this.user_ids.concat(arr);
			this.user_ids = this.unique(this.user_ids);
			this.teacher_list1[this.typeIndex1].data[this.dataIndex1].isQuan = true;
		},
		QuanTap1(){
			let arr = [];
			for (let t in this.teacher_list1[this.typeIndex1].data[this.dataIndex1].teacher) {
				this.teacher_list1[this.typeIndex1].data[this.dataIndex1].teacher[t].checked = false;
			}
			for (let i in this.teacher_list1) {
				for (let j in this.teacher_list1[i].data) {
					for (let t in this.teacher_list1[i].data[j].teacher) {
						if(this.teacher_list1[i].data[j].teacher[t].checked){
							arr.push(this.teacher_list1[i].data[j].teacher[t].teacher_id);
						}
					}
				}
			}
			this.user_ids = arr;
			this.user_ids = this.unique(this.user_ids);
			this.teacher_list1[this.typeIndex].data[this.dataIndex].isQuan = false;
		},
		// 选择老师
		teachTap1(item) {
			let arr = [];
			for (let i in this.teacher_list1) {
				for (let j in this.teacher_list1[i].data) {
					for (let t in this.teacher_list1[i].data[j].teacher) {
						if (this.teacher_list1[i].data[j].teacher[t].teacher_id == item.teacher_id && !this.teacher_list1[i].data[j].teacher[t].checked) {
							this.teacher_list[i].data[j].teacher[t].checked = true;
						} else if (this.teacher_list1[i].data[j].teacher[t].teacher_id == item.teacher_id && this.teacher_list1[i].data[j].teacher[t].checked) {
							this.teacher_list1[i].data[j].teacher[t].checked = false;
						}
						if (this.teacher_list1[i].data[j].teacher[t].checked) {
							arr.push(this.teacher_list1[i].data[j].teacher[t].teacher_id);
						}
					}
				}
			}
			this.user_ids = arr;
			this.user_ids = this.unique(this.user_ids);
		},
		removeTag1() {
			for (let i in this.teacher_lis1t) {
				for (let j in this.teacher_list1[i].data) {
					for (let t in this.teacher_list1[i].data[j].teacher) {
						if (this.user_ids.length) {
							for (let c in this.user_ids) {
								if (this.teacher_list1[i].data[j].teacher[t].teacher_id == this.user_ids[c]) {
									this.teacher_list1[i].data[j].teacher[t].checked = true;
								} else {
									this.teacher_list1[i].data[j].teacher[t].checked = false;
								}
							}
						} else {
							this.teacher_list1[i].data[j].teacher[t].checked = false;
						}
					}
				}
			}
		},
		// 切换分类
		typeTap1(index) {
			this.typeIndex1 = index;
			this.dataIndex1 = 0;
		},
		dataTap1(index) {
			this.dataIndex1 = index;
		},
		// 获取教研室列表
		getJiaoyanList(){
			this.$api.setting.getStaffList({}).then(res=>{
				if(res.data.code===1){
					this.jxlist = res.data.data.rows;
				}
			})
		},
		// 获取系部列表
		getXibuList(){
			this.$api.setting.getXiList({}).then(res=>{
				if(res.data.code===1){
					this.xibuList = res.data.data.rows;
				}
			})
		},
		dataTap(index) {
			this.dataIndex = index;
		},
		typeTap(index) {
			this.typeIndex = index;
		},
		handleSelectionChange(val) {
			let arr = [];
			for (let i in val) {
				arr.push(val[i].id);
			}
			this.ids = arr;
			console.log(this.ids);
		},
		handleSizeChange(val) {
			console.log(`每页 ${val} 条`);
		},
		handleCurrentChange(val) {
			this.page = val;
			this.classData();
		},
		handleClose(done) {
			this.initialize();
			done();
		},
		// 初始化表单
		initialize() {
			this.sn = '';
			this.code = '';
			this.name = '';
			this.shorter = '';
			this.years  = '';
			this.start_time = '';
			this.jy_id = '';	
			this.credit = '';
			this.compulsory_credits = '';	
			this.limited_credits = '';
			this.optional_credits = '';
			this.person_user_id = '';
			this.status = 1;
			this.xi_ids = [];
			this.user_ids  = [];
		},
		// 专业列表
		classData() {
			this.$api.setting
				.getZyList({
					limit: this.limit,
					page: this.page
				})
				.then(res => {
					this.classList = res.data.data.rows;
					this.total = res.data.data.total;
				});
		},
		// 弹窗
		async openDialog(type, item) {
			this.type = type;
      this.disciplinePage = 1;
			if (type == 1) {
        this.checkDisciplines = [];
				this.VisibleTitle = '添加专业';
			} else if (type == 2) {
				this.VisibleTitle = '编辑专业';
				this.id = item.id;
				this.sn = item.sn;
				this.code = item.code;
				this.name = item.name;
				this.shorter = item.shorter;
				this.years  = item.years;
				this.start_time = item.start_time;
				this.jy_id = item.jy_id;	
				this.credit = item.credit;
				this.compulsory_credits = item.compulsory_credits;	
				this.limited_credits = item.limited_credits;
				this.optional_credits = item.optional_credits;
				this.person_user_id = item.person_user_id;
				this.status = item.status;
        this.checkDisciplines = (item.discipline_ids ?? '').split(',').map(val => Number(val));
        console.log('check', this.checkDisciplines)
				if(item.user_ids){
					this.user_ids = item.user_ids.split(",");
					this.user_ids.forEach((item,index) =>{
						this.user_ids[index] = parseInt(this.user_ids[index])
					})
					for (let i in this.teacher_list1) {
						for (let j in this.teacher_list1[i].data) {
							for (let t in this.teacher_list1[i].data[j].teacher) {
								for (let s in this.user_ids) {
									if (this.teacher_list1[i].data[j].teacher[t].teacher_id == this.user_ids[s]) {
										this.teacher_list1[i].data[j].teacher[t].checked = true;
									}
								}
							}
						}
					}
				}
				if(item.xi_ids){
					this.xi_ids = item.xi_ids.split(",");
					this.xi_ids.forEach((item,index) =>{
						this.xi_ids[index] = parseInt(this.xi_ids[index])
					})
				}
			}
			this.addVisible = true;
		},
		// 关闭弹窗
		closeVisible() {
			this.initialize();
			this.addVisible = false;
		},
		// 添加、编辑专业
		submit() {
			if (this.type == 1) {
				this.addclass();
			} else if (this.type == 2) {
				this.editclass();
			}
			this.initialize();
		},
		// 添加专业
		addclass() {
			let data = {
				sn:this.sn,
				code:this.code,
				name:this.name,
				shorter:this.shorter,
				years:this.years,
				start_time:this.start_time,
				jy_id:this.jy_id,	
				credit:this.credit,
				compulsory_credits:this.compulsory_credits,	
				limited_credits:this.limited_credits,
				optional_credits:this.optional_credits,
				person_user_id:this.person_user_id,
				statu:this.statu,
				xi_ids:this.xi_ids.toString(),
				user_ids:this.user_ids.toString(),
        discipline_ids: this.checkDisciplines.join(',')
			}
			this.$api.setting.addZy(data).then(res => {
					if (res.data.code == 1) {
						this.$message.success('添加成功');
						this.classData();
						this.addVisible = false;
					} else {
						this.$message.error(res.data.msg);
					}
				});
		},
		// 编辑专业
		editclass() {
			this.$api.setting
				.editZy({
					sn:this.sn,
					code:this.code,
					name:this.name,
					shorter:this.shorter,
					years:this.years,
					start_time:this.start_time,
					jy_id:this.jy_id,	
					credit:this.credit,
					compulsory_credits:this.compulsory_credits,	
					limited_credits:this.limited_credits,
					optional_credits:this.optional_credits,
					person_user_id:this.person_user_id,
					statu:this.statu,
					xi_ids:this.xi_ids.toString(),
					user_ids:this.user_ids.toString(),
          discipline_ids: this.checkDisciplines.join(','),
					id: this.id
				})
				.then(res => {
					if (res.data.code == 1) {
						this.$message.success('修改成功');
						this.classData();
						this.addVisible = false;
					} else {
						this.$message.error(res.data.msg);
					}
				});
		},
		// 删除专业
		delclass() {
			let _this = this;
			if (this.ids.length) {
				this.$confirm('确定要删除？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消'
				}).then(() => {
					this.$api.setting
						.delZy({
							id: this.ids
						})
						.then(res => {
							if (res.data.code == 1) {
								_this.$message.success('删除成功');
								_this.classData();
							} else {
								_this.$message.error(res.data.msg);
							}
						});
				});
			} else {
				this.$message.error('请选择要删除的专业');
			}
		},
		// 教师列表
		teacherList() {
			this.$api.pepople.teacherList({}).then(res => {
				if (res.data.code == 1) {
					this.teacher_list = res.data.data;
					this.teacher_list1 = res.data.data;
					for (let i in this.teacher_list) {
						for (let j in this.teacher_list[i].data) {
							for (let t in this.teacher_list[i].data[j].teacher) {
								this.selectList = this.selectList.concat(this.teacher_list[i].data[j].teacher[t]);
							}
						}
					}
					for (let i in this.teacher_list1) {
						for (let j in this.teacher_list1[i].data) {
							for (let t in this.teacher_list1[i].data[j].teacher) {
								this.nums = this.nums.concat(this.teacher_list1[i].data[j].teacher[t].teacher_id);
								this.selectList1 = this.selectList1.concat(this.teacher_list1[i].data[j].teacher[t]);
							}
						}
					}
					this.nums = this.unique(this.nums);
					this.selectList = this.unique2(this.selectList);
					this.selectList1 = this.unique2(this.selectList1);
				}
			});
		},
		// 重置
		reset() {
			this.person_user_id = '';
			this.teacherList();
		},
		teachTap(item) {
			for (let i in this.teacher_list) {
				for (let j in this.teacher_list[i].data) {
					for (let t in this.teacher_list[i].data[j].teacher) {
						if (this.teacher_list[i].data[j].teacher[t].teacher_id == item.teacher_id && !this.teacher_list[i].data[j].teacher[t].checked) {
							this.teacher_list[i].data[j].teacher[t].checked = true;
						} else {
							this.teacher_list[i].data[j].teacher[t].checked = false;
						}
					}
				}
			}
			this.person_user_id = item.teacher_id;
		},
		unique(arr) {
			// 根据唯一标识orderId来对数组进行过滤
			const res = new Map(); //定义常量 res,值为一个Map对象实例 //返回arr数组过滤后的结果，结果为一个数组   过滤条件是，如果res中没有某个键，就设置这个键的值为1
			return arr.filter(arr => !res.has(arr) && res.set(arr, 1));
		},
		unique2(arr) {
			// 根据唯一标识orderId来对数组进行过滤
			const res = new Map(); //定义常量 res,值为一个Map对象实例 //返回arr数组过滤后的结果，结果为一个数组   过滤条件是，如果res中没有某个键，就设置这个键的值为1
			return arr.filter(arr => !res.has(arr.teacher_id) && res.set(arr.teacher_id, 1));
		},
		handleClose1(done) {
			this.typeIndex = 0;
			this.dataIndex = 0;
			this.typeIndex1 = 0;
			this.dataIndex1 = 0;
			done();
		}
	}
};
</script>

<style lang="scss">
#major {
	.content {
		margin-top: 20px;
		background-color: #ffffff;
		height: 79vh;
		.right {
			width: 100%;
			height: 75vh;
			padding: 25px 50px;
			.title {
				padding: 0 0 15px 0;
			}
			.el-table {
				table-layout: fixed;
			}
			.el-table th > .cell,
			.el-table .cell {
				overflow: hidden; /*超出部分隐藏*/
				white-space: nowrap; /*不换行*/
				text-overflow: ellipsis; /*超出部分文字以...显示*/
			}
			.el-table thead {
				background: #fafafa !important;
			}
			.el-table th {
				padding: 15px 5px !important;
				background: #fafafa !important;
			}
			.el-table::before {
				left: 0;
				bottom: 0;
				width: 100%;
				height: 0;
			}
			.el-table .btn {
				padding: 5px 10px;
				text-align: center;
				font-size: 12px;
			}
		}
	}
	.el-dialog__header {
		border-bottom: 1px solid #eeeeee;
		padding: 10px 20px;
		.el-dialog__title {
			font-size: 16px;
		}
	}
	.el-dialog__body {
		padding: 0 20px 35px 20px;
		.edit-box {
      max-height: 600px;
      overflow-y: auto;
			.input-box {
				margin-top: 35px;
				padding-left: 15px;
				.input-lebal {
					width: 20%;
					text-align: right;
					color: #aeb5b8;
					margin-right: 10px;
					span {
						color: #ff7a65;
						margin-right: 3px;
					}
				}
				.el-icon-remove-outline {
					font-size: 1.5625vw;
					color: #d7d9db;
					margin-left: 10px;
				}
				.input-item {
					.el-select .el-input.is-disabled .el-input__inner {
						cursor: default;
					}
					.el-input.is-disabled .el-input__inner {
						background-color: #ffffff;
						cursor: default;
					}
					.el-input__prefix,
					.el-input__suffix {
						display: none;
					}
				}
			}
			.avatar-uploader .el-upload {
				border: 1px dashed #d9d9d9;
				border-radius: 6px;
				cursor: pointer;
				position: relative;
				overflow: hidden;
			}
			.avatar-uploader .el-upload:hover {
				border-color: #409eff;
			}
			.avatar-uploader-icon {
				font-size: 28px;
				color: #8c939d;
				width: 90px;
				height: 90px;
				line-height: 90px;
				text-align: center;
			}
			.avatar {
				width: 90px;
				height: 90px;
				display: block;
			}
		}
		.header {
			padding: 10px 0;
			font-weight: bold;
			font-size: 16px;
		}
		.con-box {
			display: flex;
			flex-wrap: wrap;
			max-height: 60vh;
			overflow-y: scroll;
			.con-item {
				width: 120px;
				background: #f1f1f1;
				margin-right: 10px;
				margin-bottom: 10px;
				padding: 10px;
				border-radius: 10px;
				.user-pic {
					width: 40px;
					height: 40px;
					border-radius: 50%;
					background-color: #007aff;
					color: #ffffff;
					margin-right: 10px;
				}
				.nv {
					background-color: #cdafb8;
				}
			}
		}
		.con-box > div:nth-child(3n) {
			margin-right: 0;
		}
	}
	.el-dialog__footer {
		padding: 15px 20px 15px;
		box-shadow: 0px -5px 10px -5px #eeeeee;
	}

	.el-table__column-filter-trigger {
		.el-icon-arrow-down:before {
			content: '\E790';
			font-size: 16px;
		}
	}
}
.selectShow {
	.el-dialog__header {
		border-bottom: 1px solid #eeeeee;
		padding: 10px 20px;
		.el-dialog__title {
			font-size: 16px;
		}
	}
	.el-dialog__body {
		padding: 0;
		.user-list-box {
			.user-list-head {
				padding: 10px 20px;
				border-bottom: 1px solid #eeeeee;
				.user-type-list {
					.user-type-item {
						color: #8d9da6;
						cursor: pointer;
					}
					.active {
						color: #007aff;
						position: relative;
					}
					.active::after {
						position: absolute;
						width: 25px;
						height: 2px;
						background-color: #007aff;
						content: '';
						bottom: -5px;
						left: 0;
					}
					span {
						color: #cccccc;
						margin: 0 8px;
					}
				}
			}
			.user-list-content {
				height: 40vh;
				.left {
					height: 98%;
					overflow-y: auto;
					border-right: 1px solid #eeeeee;
					padding-top: 5px;
					.left-item {
						padding: 0 20px;
						height: 40px;
						line-height: 40px;
						color: #a4a4a4;
						cursor: pointer;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}
					.active {
						background-color: #e4f1ff;
						color: #2d8cf0;
						position: relative;
					}
					.active:after {
						position: absolute;
						top: 0;
						bottom: 0;
						right: 0;
						width: 2px;
						content: '';
						background-color: #2d8cf0;
					}
				}
				.right {
					height: 37vh;
					padding: 10px 25px;
					overflow-y: auto;
					.list {
						display: flex;
						flex-wrap: wrap;
						.item {
							cursor: pointer;
							padding: 5px 15px;
							border-radius: 3px;
							background-color: #f3f3f3;
							margin-right: 10px;
							margin-bottom: 10px;
						}
						.active {
							background-color: #e4f1ff;
							color: #2d8cf0;
						}
					}
				}
			}
		}
	}
	.dialog-title {
		padding: 10px 0 5px 0;
	}
	.el-dialog__footer {
		padding: 15px 20px 15px;
		box-shadow: 0px -5px 10px -5px #eeeeee;
	}
}
</style>
